import { Headers } from "./generic";
import { paramsParser } from "../core/paramsParser";
import { assertSuccess } from "../core/assert";
import axios from "../core/axios";
import { removeUnchanged, trimObjectStrings } from "../helpers/common";
import dayjs from "dayjs";
import type {
  CompetitionEntity,
  CompetitionEntriesListParams,
  CompetitionEntryEntity,
  CompetitionEntryScore,
  CompetitionEntryScoreListParams,
  CompetitionListParams,
  CompetitionPostBody,
} from "../types/competition";
import type { CompetitionExpansionTypes } from "../types/generic";
import type { CollectionResult, MessageResult, Result } from "../types/result";

export class Competition {
  static async search(params: CompetitionListParams = {}) {
    // https://gitlab.com/namicomi/backend/-/blob/develop/application/src/Competition/Controller/CompetitionController.php?ref_type=heads#L49
    const resp = await axios<CollectionResult<CompetitionEntity>>(
      `/competition` + paramsParser(params),
    );

    return assertSuccess(resp.data);
  }

  static async searchAdmin(params: CompetitionListParams = {}, auth: string) {
    const resp = await axios<CollectionResult<CompetitionEntity>>(
      `/admin/competition` + paramsParser(params),
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data);
  }

  static async get(id: string, includes: CompetitionExpansionTypes[] = []) {
    // https://gitlab.com/namicomi/backend/-/blob/develop/application/src/Competition/Controller/CompetitionController.php?ref_type=heads#L33
    const resp = await axios<Result<CompetitionEntity>>(
      `/competition/${id}` + paramsParser({ includes }),
    );

    return assertSuccess(resp.data).data;
  }

  static async getAdmin(
    id: string,
    includes: CompetitionExpansionTypes[] = [],
    auth: string,
  ) {
    const resp = await axios<Result<CompetitionEntity>>(
      `/admin/competition/${id}` + paramsParser({ includes }),
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async create(template: CompetitionTemplate, auth: string) {
    const resp = await axios<Result<CompetitionEntity>>(
      `/admin/competition/create`,
      {
        method: "POST",
        headers: Headers.Bearer(auth),
        data: trimObjectStrings(template.getBody()),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async edit(
    id: string,
    version: number,
    template: CompetitionTemplate,
    auth: string,
  ) {
    if (Object.keys(template.getBody()).length === 0) return;

    const resp = await axios<Result<CompetitionEntity>>(
      `/admin/competition/${id}`,
      {
        method: "PUT",
        headers: Headers.Bearer(auth),
        data: {
          ...trimObjectStrings(template.getBody()),
          version: version,
        },
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async seed(id: string, auth: string) {
    const resp = await axios(`/admin/competition/${id}/seed`, {
      method: "POST",
      headers: Headers.Bearer(auth),
    });

    return resp.status === 303;
  }

  static async getEntries(
    id: string,
    params: CompetitionEntriesListParams = {},
  ) {
    const resp = await axios<CollectionResult<CompetitionEntryEntity>>(
      `/competition/${id}/entries` + paramsParser(params),
    );

    return assertSuccess(resp.data);
  }

  static async getEntriesAdmin(
    id: string,
    params: CompetitionEntriesListParams = {},
    auth: string,
  ) {
    const resp = await axios<CollectionResult<CompetitionEntryEntity>>(
      `/admin/competition/${id}/entries` + paramsParser(params),
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async getScores(
    id: string,
    params: CompetitionEntryScoreListParams = {},
  ) {
    const resp = await axios<CollectionResult<CompetitionEntryScore>>(
      `/competition/${id}/scores` +
        paramsParser({ ...params, maxFirstChaptersViewCountAbove: 999 }),
    );

    return assertSuccess(resp.data);
  }
}

export class CompetitionEntry {
  static async updateParticipation(
    participating: boolean,
    competitionId: string,
    titleId: string,
    auth: string,
  ) {
    const resp = await axios<
      MessageResult<"Competition Participation Updated.">
    >(`/competition/${competitionId}/participation`, {
      method: "POST",
      headers: Headers.Bearer(auth),
      data: { titleId, participating },
    });

    return assertSuccess(resp.data).message;
  }

  static async participate(
    competitionId: string,
    titleId: string,
    auth: string,
  ) {
    return CompetitionEntry.updateParticipation(
      true,
      competitionId,
      titleId,
      auth,
    );
  }

  static async withdraw(competitionId: string, titleId: string, auth: string) {
    return CompetitionEntry.updateParticipation(
      false,
      competitionId,
      titleId,
      auth,
    );
  }
}

export class CompetitionTemplate {
  template: CompetitionPostBody;
  initial?: CompetitionEntity;

  constructor() {
    this.template = {
      name: {},
      description: {},
      selectionService: "competition.selector.published_titles",
      scoringService: "competition.scorer.weighted_views_comments_follows",
      updating: false,
    };
  }

  static parseFromCompetition(
    competition: CompetitionEntity,
  ): CompetitionPostBody {
    const {
      name,
      description,
      slug,
      startAt,
      endAt,
      scoringService,
      selectionService,
      updating,
    } = competition.attributes;

    return {
      name,
      description,
      slug,
      startAt: startAt ? dayjs(startAt).format("YYYY-MM-DDTHH:mm:ss") : null,
      endAt: endAt ? dayjs(endAt).format("YYYY-MM-DDTHH:mm:ss") : null,
      scoringService,
      selectionService,
      updating,
    };
  }

  parseFromCompetition(competition: CompetitionEntity) {
    this.initial = competition;
    this.template = CompetitionTemplate.parseFromCompetition(competition);
    return this;
  }

  getBody() {
    const template = {
      ...this.template,
    } as CompetitionPostBody;

    const oldTemplate = this.initial
      ? CompetitionTemplate.parseFromCompetition(this.initial)
      : undefined;

    return removeUnchanged(template, oldTemplate);
  }

  reset() {
    if (this.initial) this.parseFromCompetition(this.initial);
    else
      this.template = {
        name: {},
        description: {},
        selectionService: "competition.selector.published_titles",
        scoringService: "competition.scorer.weighted_views_comments_follows",
        updating: false,
      };
  }
}
